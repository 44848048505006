import { Entity } from '@backstage/catalog-model';
import { AwsAccountEntity } from './types';

const extractor: RegExp = /[^< ]+(?=>)/g;

export function isAwsAccountEntity(entity: Entity): entity is AwsAccountEntity {
  return entity.spec !== undefined && entity.spec.type === 'aws-account';
}

// John Doe <john.doe@example.com> -> john.doe@example.com
export function extractEmail(authority: string): string {
  const extracted = authority.match(extractor);
  if (extracted === null) {
    throw new Error('failed to extract email from authority string');
  }

  return extracted[0];
}
