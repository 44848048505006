import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const untaggedResourcesDashboardPlugin = createPlugin({
  id: 'untagged-resources-dashboard',
  routes: {
    root: rootRouteRef,
  },
});

export const UntaggedResourcesDashboardPage =
  untaggedResourcesDashboardPlugin.provide(
    createRoutableExtension({
      name: 'UntaggedResourcesDashboardPage',
      component: () =>
        import('./components/ResourcesDashboard').then(
          m => m.ResourcesDashboard,
        ),
      mountPoint: rootRouteRef,
    }),
  );
