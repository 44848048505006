import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const contributePlugin = createPlugin({
  id: 'contribute',
  routes: {
    root: rootRouteRef,
  },
});

export const ContributePage = contributePlugin.provide(
  createRoutableExtension({
    name: 'ContributePage',
    component: () =>
      import('./components/ContributionPageComponent').then(
        m => m.ContributionPageComponent,
      ),
    mountPoint: rootRouteRef,
  }),
);
