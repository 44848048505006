/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  Content,
  ContentHeader,
  CreateButton,
  InfoCard,
  PageWithHeader,
  SupportButton,
  TableColumn,
  TableProps,
} from '@backstage/core-components';
import { configApiRef, useApi, useRouteRef } from '@backstage/core-plugin-api';
import { CatalogTable, CatalogTableRow } from '@backstage/plugin-catalog';
import {
  CatalogFilterLayout,
  EntityKindPicker,
  EntityLifecyclePicker,
  EntityListProvider,
  EntityOwnerPicker,
  EntityTagPicker,
  EntityTypePicker,
  UserListFilterKind,
  UserListPicker,
} from '@backstage/plugin-catalog-react';
import React from 'react';
import { apiDocsPlugin } from '@backstage/plugin-api-docs';
import { usePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { Link, makeStyles } from '@material-ui/core';

const defaultColumns: TableColumn<CatalogTableRow>[] = [
  CatalogTable.columns.createTitleColumn({ hidden: true }),
  CatalogTable.columns.createNameColumn({ defaultKind: 'API' }),
  CatalogTable.columns.createOwnerColumn(),
  CatalogTable.columns.createSpecTypeColumn(),
  CatalogTable.columns.createSpecLifecycleColumn(),
  CatalogTable.columns.createMetadataDescriptionColumn(),
];

const useStyles = makeStyles({
  banner: {
    'background-color': '#fcc934',
    color: '#0d0d0d',
    '& a': {
      color: '#0d0d0d',
      'text-decoration': 'underline',
    },
  },
});

/**
 * DefaultApiExplorerPageProps
 * @public
 */
export type DefaultApiExplorerPageProps = {
  initiallySelectedFilter?: UserListFilterKind;
  columns?: TableColumn<CatalogTableRow>[];
  actions?: TableProps<CatalogTableRow>['actions'];
};

/**
 * DefaultApiExplorerPage
 * @public
 */
export const CustomApiExplorerPage = (props: DefaultApiExplorerPageProps) => {
  const { initiallySelectedFilter = 'all', columns, actions } = props;
  const classes = useStyles();

  const configApi = useApi(configApiRef);
  const generatedSubtitle = `${
    configApi.getOptionalString('organization.name') ?? 'Backstage'
  } API Explorer`;
  const registerComponentLink = useRouteRef(
    apiDocsPlugin.externalRoutes.registerApi,
  );
  // nkarpukhin - change from upstream: added permission check for catalog entity creation
  const createPermission = usePermission({
    permission: catalogEntityCreatePermission,
  });
  const isAllowed = createPermission.allowed;

  return (
    <PageWithHeader
      themeId="apis"
      title="APIs"
      subtitle={generatedSubtitle}
      pageTitleOverride="APIs"
    >
      <Content>
        <ContentHeader title="">
          {isAllowed && (
            <CreateButton
              title="Register Existing API"
              to={registerComponentLink?.()}
            />
          )}
          <SupportButton>All your APIs</SupportButton>
        </ContentHeader>
        {/* nkarpukhin - change from upstream: added info banner*/}
        <InfoCard className={classes.banner}>
          This page demonstrates how Backstage can be used to provide developers
          with an easy way to find and read API documentation. We would love to
          hear from you, so if you have any questions, feedback or would like to
          participate in a PoC, drop us a line in the{' '}
          <Link
            target="_blank"
            href="https://scout24.slack.com/archives/C041FPLT93N"
          >
            #developer-portal
          </Link>{' '}
          channel or fill out the following{' '}
          <Link target="_blank" href="https://forms.office.com/e/6xPeTZfwUt">
            form
          </Link>
          . You can see more API examples on the{' '}
          <Link target="_blank" href="https://demo.backstage.io/api-docs">
            Backstage demo website
          </Link>
        </InfoCard>
        <EntityListProvider>
          <CatalogFilterLayout>
            <CatalogFilterLayout.Filters>
              <EntityKindPicker initialFilter="api" hidden />
              <EntityTypePicker />
              <UserListPicker initialFilter={initiallySelectedFilter} />
              <EntityOwnerPicker />
              <EntityLifecyclePicker />
              <EntityTagPicker />
            </CatalogFilterLayout.Filters>
            <CatalogFilterLayout.Content>
              <CatalogTable
                columns={columns || defaultColumns}
                actions={actions}
              />
            </CatalogFilterLayout.Content>
          </CatalogFilterLayout>
        </EntityListProvider>
      </Content>
    </PageWithHeader>
  );
};
