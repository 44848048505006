/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useMemo } from 'react';
import {
  CatalogTable,
  CatalogTableProps,
  CatalogTableRow,
} from '@backstage/plugin-catalog';
import { TableColumn, TableProps } from '@backstage/core-components';
import { ANNOTATION_VIEW_URL } from '@backstage/catalog-model';
import { Typography } from '@material-ui/core';
import OpenInNew from '@material-ui/icons/OpenInNew';
import StarBorder from '@material-ui/icons/StarBorder';
import {
  useEntityList,
  useStarredEntities,
} from '@backstage/plugin-catalog-react';
import { withStyles } from '@material-ui/core/styles';
import Star from '@material-ui/icons/Star';
import { createEntitySpecificColumns } from './factories';

const YellowStar = withStyles({
  root: {
    color: '#f3ba37',
  },
})(Star);

export function CustomCatalogTable(props: CatalogTableProps) {
  const { columns, actions } = props;
  const { isStarredEntity, toggleStarredEntity } = useStarredEntities();
  const entityListContext = useEntityList();
  const { filters } = entityListContext;
  const columnFactories = CatalogTable.columns;
  const showTypeColumn = filters.type === undefined;

  const defaultColumns: TableColumn<CatalogTableRow>[] = useMemo(() => {
    return [
      columnFactories.createTitleColumn({ hidden: true }),
      columnFactories.createNameColumn({ defaultKind: filters.kind?.value }),
      ...createEntitySpecificColumns(filters.kind?.value),
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.kind?.value]); // intentionally leaving out columnFactories

  const tableColumns = useMemo(
    () =>
      typeof columns === 'function' ? columns(entityListContext) : columns,
    [columns, entityListContext],
  );

  const defaultActions: TableProps<CatalogTableRow>['actions'] = [
    ({ entity }) => {
      const url = entity.metadata.annotations?.[ANNOTATION_VIEW_URL];
      const title = 'View';

      return {
        icon: () => (
          <>
            <Typography variant="srOnly">{title}</Typography>
            <OpenInNew fontSize="small" />
          </>
        ),
        tooltip: title,
        disabled: !url,
        onClick: () => {
          if (!url) return;
          window.open(url, '_blank');
        },
      };
    },
    ({ entity }) => {
      const isStarred = isStarredEntity(entity);
      const title = isStarred ? 'Remove from favorites' : 'Add to favorites';

      return {
        cellStyle: { paddingLeft: '1em' },
        icon: () => (
          <>
            <Typography variant="srOnly">{title}</Typography>
            {isStarred ? <YellowStar /> : <StarBorder />}
          </>
        ),
        tooltip: title,
        onClick: () => toggleStarredEntity(entity),
      };
    },
  ];

  const typeColumn = (tableColumns || defaultColumns).find(
    c => c.title === 'Type',
  );
  if (typeColumn) {
    typeColumn.hidden = !showTypeColumn;
  }

  return (
    <CatalogTable
      {...props}
      columns={columns || defaultColumns}
      actions={actions || defaultActions}
      tableOptions={{ pageSize: 50 }}
    />
  );
}

CustomCatalogTable.columns = CatalogTable.columns;
