import { EntityFilter } from '@backstage/plugin-catalog-react';
import { Entity } from '@backstage/catalog-model';
import { isAwsAccountEntity } from '@internal/plugin-aws-resources-common';

export class OrphanedAccountsFilter implements EntityFilter {
  filterEntity(entity: Entity): boolean {
    if (!isAwsAccountEntity(entity)) {
      return false;
    }
    const authorityRelations = entity.relations?.filter(relation =>
      relation.type.endsWith('AuthorityOf'),
    );
    let authorityFiguresCount = 1; // head is always specified
    const authority = entity.spec.authority;
    if (authority.securityAssignee) {
      authorityFiguresCount++;
    }
    if (authority.delegates) {
      authorityFiguresCount += authority.delegates.length;
    }

    // we're comparing number of established relations to number of authority figures provided
    // if they don't match - there's a stale value somewhere
    return (
      authorityRelations !== undefined &&
      authorityRelations.length !== authorityFiguresCount
    );
  }
}
