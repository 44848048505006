import { DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';
import {
  AwsResource,
  AwsResourcesApi,
  AWS_RESOURCES_PLUGIN_ID,
} from '@internal/plugin-aws-resources-common';

export interface AwsResourcesClientOptions {
  discoveryApi: DiscoveryApi;
  identityApi: IdentityApi;
}

export class AwsResourcesClient implements AwsResourcesApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;

  constructor(options: AwsResourcesClientOptions) {
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;
  }

  public async getResources(userEntityRef: string): Promise<AwsResource[]> {
    const url = new URL(
      `${await this.discoveryApi.getBaseUrl(AWS_RESOURCES_PLUGIN_ID)}/untagged`,
    );
    const authHeader = await this.getAuthenticationHeader();
    return fetch(url, {
      method: 'POST', // sending entity ref in a body of a JSON instead of query param, because it might contain PII
      headers: { 'Content-Type': 'application/json', ...authHeader },
      body: JSON.stringify({ ref: userEntityRef }),
    })
      .then<AwsResource[]>(response => {
        // might return 503 if the userRefs are not loaded yet
        if (!response.ok && response.status !== 503) {
          // eslint-disable-next-line no-console
          console.error(`got ${response.status} from resources api`);
          return [];
        }
        return response.json();
      })
      .catch(async error => {
        // eslint-disable-next-line no-console
        console.error(await ResponseError.fromResponse(error));
        return [];
      });
  }

  private async getAuthenticationHeader(): Promise<
    Record<string, string> | undefined
  > {
    const token = (await this.identityApi.getCredentials()).token;

    return token ? { Authorization: `Bearer ${token}` } : undefined;
  }
}
