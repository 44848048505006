/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  Entity,
  RELATION_OWNED_BY,
  RELATION_PART_OF,
} from '@backstage/catalog-model';
import {
  EntityRefLinks,
  getEntityRelations,
} from '@backstage/plugin-catalog-react';
import { Chip, Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { AboutField } from '@backstage/plugin-catalog';
import { isAwsAccountEntity } from '@internal/plugin-aws-resources-common';
import CopyableText from '../../common/CopyableText';
import { LinksGroup } from './LinksGroup';

const useStyles = makeStyles({
  description: {
    wordBreak: 'break-word',
  },
});

/**
 * Props for {@link CustomAboutContent}.
 *
 * @public
 */
export interface AboutContentProps {
  entity: Entity;
  showLinks?: boolean;
}

function capitalizeNamespace(namespace: string | undefined): string {
  if (!namespace) {
    return 'Scout24';
  }
  return `${namespace.charAt(0).toUpperCase()}${namespace.slice(1)}`;
}

/** @public */
export function CustomAboutContent(props: AboutContentProps) {
  const { entity } = props;
  const classes = useStyles();
  const isSystem = entity.kind.toLocaleLowerCase('en-US') === 'system';
  const isResource = entity.kind.toLocaleLowerCase('en-US') === 'resource';
  const isComponent = entity.kind.toLocaleLowerCase('en-US') === 'component';
  const isAPI = entity.kind.toLocaleLowerCase('en-US') === 'api';
  const isTemplate = entity.kind.toLocaleLowerCase('en-US') === 'template';
  const isLocation = entity.kind.toLocaleLowerCase('en-US') === 'location';
  const isGroup = entity.kind.toLocaleLowerCase('en-US') === 'group';
  const isAwsAccount = isAwsAccountEntity(entity);
  const sloComponent = entity.spec?.dependencyOf as string[] | undefined;

  const partOfComponentRelations = getEntityRelations(
    entity,
    RELATION_PART_OF,
    {
      kind: 'component',
    },
  );
  const partOfDomainRelations = getEntityRelations(entity, RELATION_PART_OF, {
    kind: 'domain',
  });
  const ownedByRelations = getEntityRelations(entity, RELATION_OWNED_BY);
  const underHeadAuthorityRelations = getEntityRelations(
    entity,
    'underHeadAuthorityOf',
  );

  const qualifiedId = entity.metadata.namespace
    ? `${capitalizeNamespace(entity.metadata.namespace)}/${
        entity.metadata.name
      }`
    : entity.metadata.name;

  return (
    <Grid container>
      <AboutField label="Description" gridSizes={{ xs: 12 }}>
        <Typography variant="body2" paragraph className={classes.description}>
          {entity?.metadata?.description || 'No description'}
        </Typography>
      </AboutField>
      <AboutField
        label="Owner"
        value="No Owner"
        gridSizes={{ xs: 12, sm: 6, lg: 4 }}
      >
        {ownedByRelations.length > 0 && (
          <EntityRefLinks entityRefs={ownedByRelations} defaultKind="group" />
        )}
      </AboutField>
      {isAwsAccount && (
        <>
          <AboutField label="ID" value={entity.metadata.name} />
          <AboutField label="Region">
            <Chip size="small" key="region" label={entity.spec.region} />
          </AboutField>
          <AboutField label="Cost Centre">
            <Chip
              size="small"
              key="cost_centre"
              label={entity.spec.costCentre}
            />
          </AboutField>
          <AboutField
            label="Head Authority"
            value="No entity found"
            gridSizes={{ xs: 12, sm: 6, lg: 4 }}
          >
            {underHeadAuthorityRelations.length > 0 && (
              <EntityRefLinks
                entityRefs={underHeadAuthorityRelations}
                defaultKind="user"
              />
            )}
          </AboutField>
        </>
      )}
      {(isSystem || partOfDomainRelations.length > 0) && (
        <AboutField
          label="Domain"
          value="No Domain"
          gridSizes={{ xs: 12, sm: 6, lg: 4 }}
        >
          {partOfDomainRelations.length > 0 && (
            <EntityRefLinks
              entityRefs={partOfDomainRelations}
              defaultKind="domain"
            />
          )}
        </AboutField>
      )}
      {isComponent && partOfComponentRelations.length > 0 && (
        <AboutField
          label="Parent Component"
          value="No Parent Component"
          gridSizes={{ xs: 12, sm: 6, lg: 4 }}
        >
          <EntityRefLinks
            entityRefs={partOfComponentRelations}
            defaultKind="component"
          />
        </AboutField>
      )}
      {(isAPI ||
        isComponent ||
        isResource ||
        isTemplate ||
        isGroup ||
        isLocation ||
        typeof entity?.spec?.type === 'string') && (
        <AboutField
          label="Type"
          value={entity?.spec?.type as string}
          gridSizes={{ xs: 12, sm: 6, lg: 4 }}
        />
      )}
      {(isAPI || isComponent || typeof entity?.spec?.lifecycle === 'string') &&
        !isAwsAccount && (
          <AboutField
            label="Lifecycle"
            value={entity?.spec?.lifecycle as string}
            gridSizes={{ xs: 12, sm: 6, lg: 4 }}
          />
        )}
      {isComponent && !isAwsAccount && (
        <AboutField label="component-id" gridSizes={{ xs: 12, sm: 6, lg: 4 }}>
          <CopyableText text={qualifiedId} />
        </AboutField>
      )}
      {isResource && (
        <AboutField label="component-id" gridSizes={{ xs: 12, sm: 6, lg: 4 }}>
          <CopyableText
            text={`${capitalizeNamespace(entity.metadata.namespace)}/${(
              sloComponent?.[0] ?? ''
            ).slice('component:scout24/'.length)}`}
          />
        </AboutField>
      )}
      {isAPI && (
        <AboutField label="portal id" gridSizes={{ xs: 12, sm: 6, lg: 4 }}>
          <CopyableText text={qualifiedId.toLowerCase()} />
        </AboutField>
      )}

      {props?.showLinks && <LinksGroup links={entity.metadata.links} />}
    </Grid>
  );
}
