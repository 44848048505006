import { createApiRef } from '@backstage/core-plugin-api';
import {
  AwsAccountForm,
  AwsAccountCreationResponse,
  AdRoleGroupsResponse,
} from '@internal/plugin-aws-account-creation-common';

export const awsAccountCreationApiRef = createApiRef<AwsAccountCreationApi>({
  id: 'plugin.aws-account-creation',
});

export interface AwsAccountCreationApi {
  postAccountCreationForm(
    account: AwsAccountForm,
  ): Promise<AwsAccountCreationResponse>;
  getAdRoleGroups(): Promise<AdRoleGroupsResponse>;
}

export class MockAwsAccountCreationApi implements AwsAccountCreationApi {
  postAccountCreationForm(
    _: AwsAccountForm,
  ): Promise<AwsAccountCreationResponse> {
    return Promise.resolve({});
  }
  getAdRoleGroups(): Promise<AdRoleGroupsResponse> {
    return Promise.resolve({
      user: ['Test-AD-Rolegroup-Engineer'],
      rest: ['Some-Other-AD-Rolegroup-Engineer'],
    });
  }
}
