import { extractEmail } from '@internal/plugin-aws-resources-common';
import React, { useState } from 'react';
import { CopyTextButton } from '@backstage/core-components';

/**
 * Creates a React component which renders a {@link CopyTextButton}, showing the copy button only on hover
 * Optionally can convert passed-in email into a `mailto:` link
 * @param props
 * @constructor
 */
const CopyableText = (props: {
  text: string;
  extractEmail?: boolean;
}): React.JSX.Element => {
  const { text } = props;
  let textToCopy = text;
  let textElement: React.JSX.Element = <>{text}</>;
  if (props.extractEmail) {
    try {
      const email = extractEmail(text);
      textToCopy = email;
      const name = text.slice(0, text.indexOf('<'));
      const link = <a href={`mailto:${email}`}>{email}</a>;
      textElement = (
        <>
          {name}
          {'<'}
          {link}
          {'>'}
        </>
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }
  const [hover, setHover] = useState<boolean>(false);
  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{ width: '100%' }}
    >
      {textElement}
      <span
        style={{ visibility: hover ? 'visible' : 'hidden' }}
        className="copyTextButton"
      >
        <CopyTextButton text={textToCopy} />
      </span>
    </div>
  );
};

export default CopyableText;
