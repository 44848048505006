import React, { useEffect, useState } from 'react';
import {
  DefaultEntityFilters,
  useEntityList,
} from '@backstage/plugin-catalog-react';
import {
  FormControl,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { AuthorityEntityFilter } from './AuthorityEntityFilter';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import useAsync from 'react-use/lib/useAsync';
import { Progress } from '@backstage/core-components';

type CustomFilters = DefaultEntityFilters & {
  authority?: AuthorityEntityFilter;
};

export const AuthorityEntityPicker = () => {
  const identityApi = useApi(identityApiRef);
  const { loading, value: ref } = useAsync(async () => {
    const { userEntityRef } = await identityApi.getBackstageIdentity();
    return userEntityRef;
  }, []);

  const { updateFilters } = useEntityList<CustomFilters>();

  // Toggles the value, depending on whether it's already selected
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    updateFilters({
      authority:
        ref !== undefined && checked
          ? new AuthorityEntityFilter(ref)
          : undefined,
    });
  }, [ref, updateFilters, checked]);

  return (
    <>
      {loading && <Progress />}
      {!loading && (
        <FormControl component="fieldset">
          <Typography variant="button">Authority</Typography>
          <FormGroup>
            <FormControlLabel
              key="authority-filter"
              control={
                <Checkbox
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
              }
              label="Under my authority"
            />
          </FormGroup>
        </FormControl>
      )}
    </>
  );
};
