import {
  createComponentExtension,
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const techHealthPlugin = createPlugin({
  id: 'tech-health',
  routes: {
    root: rootRouteRef,
  },
});

export const TechHealthReportPage = techHealthPlugin.provide(
  createRoutableExtension({
    name: 'TechHealthReportPage',
    component: () => import('./components/ReportPage').then(m => m.ReportPage),
    mountPoint: rootRouteRef,
  }),
);

export const EntityCheckResults = techHealthPlugin.provide(
  createComponentExtension({
    component: {
      lazy: () =>
        import('./components/EntityCheckResults').then(
          m => m.EntityCheckResults,
        ),
    },
  }),
);
