import { AwsAccountCreationApi } from './api';
import { ResponseError } from '@backstage/errors';
import { DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';
import {
  AdRoleGroupsResponse,
  AWS_ACCOUNT_CREATION_PLUGIN_ID,
  AwsAccountCreationResponse,
  AwsAccountForm,
} from '@internal/plugin-aws-account-creation-common';

export class AwsAccountCreationClient implements AwsAccountCreationApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;

  constructor(options: {
    discoveryApi: DiscoveryApi;
    identityApi: IdentityApi;
  }) {
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;
  }

  async postAccountCreationForm(
    account: AwsAccountForm,
  ): Promise<AwsAccountCreationResponse> {
    const url = new URL(
      `${await this.discoveryApi.getBaseUrl(
        AWS_ACCOUNT_CREATION_PLUGIN_ID,
      )}/account`,
    );
    const authHeader = await this.getAuthenticationHeader();
    let response: Response;
    try {
      response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader },
        body: JSON.stringify(account),
      });
    } catch (error) {
      return {
        error: `Got unexpected error when submitting the form: ${error}`,
      };
    }
    if (!response.ok) {
      const err = await ResponseError.fromResponse(response);
      // eslint-disable-next-line no-console
      console.error(err);
      return { error: `Failed to submit the form: ${err.message}` };
    }
    return response.json();
  }

  async getAdRoleGroups(): Promise<AdRoleGroupsResponse> {
    const url = new URL(
      `${await this.discoveryApi.getBaseUrl('aws-account-creation')}/groups`,
    );
    const authHeader = await this.getAuthenticationHeader();
    let response: Response;
    try {
      response = await fetch(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', ...authHeader },
      });
    } catch (error) {
      throw new Error(
        `Got unexpected error when fetching AD role groups: ${error}`,
      );
    }
    if (!response.ok) {
      const err = await ResponseError.fromResponse(response);
      throw new Error(`Failed to fetch AD RoleGroups: ${err.message}`);
    }
    return response.json();
  }

  private async getAuthenticationHeader(): Promise<
    Record<string, string> | undefined
  > {
    const { token } = await this.identityApi.getCredentials();
    return token ? { Authorization: `Bearer ${token}` } : undefined;
  }
}
