import { TableColumn } from '@backstage/core-components';
import { CatalogTable, CatalogTableRow } from '@backstage/plugin-catalog';
import { Entity } from '@backstage/catalog-model';
import {
  EntityRefLink,
  humanizeEntityRef,
} from '@backstage/plugin-catalog-react';
import React from 'react';

export function createAllAwsAccountColumns(): TableColumn<CatalogTableRow>[] {
  return [
    createAwsAccountNameColumn(),
    createCommonAwsAccountColumn('Account ID', 'entity.metadata.name'),
    createCommonAwsAccountColumn('Email', 'entity.spec.distributionList'),
    createCommonAwsAccountColumn('Cost Centre', 'entity.spec.costCentre'),
  ];
}

export function createAwsAccountNameColumn(): TableColumn<CatalogTableRow> {
  return {
    ...createCommonAwsAccountColumn('Account Name', 'entity.metadata.title'),
    highlight: true,
    customSort({ entity: entity1 }, { entity: entity2 }) {
      // TODO from upstream: We could implement this more efficiently by comparing field by field.
      // This has similar issues as above.
      return formatContent(entity1).localeCompare(formatContent(entity2));
    },
    render: ({ entity }) => (
      // TODO: substring with resources count
      <EntityRefLink
        entityRef={entity}
        defaultKind="Component"
        title={entity.metadata?.title}
      />
    ),
  };
}

export function createCommonAwsAccountColumn(
  title: string,
  field: string,
): TableColumn<CatalogTableRow> {
  return {
    title,
    field,
    searchable: true,
    hidden: false,
    width: 'auto',
  };
}

function formatContent(entity: Entity): string {
  return entity.metadata?.title || humanizeEntityRef(entity, {});
}

export function createEntitySpecificColumns(
  kind: string | undefined,
): TableColumn<CatalogTableRow>[] {
  const columnFactories = CatalogTable.columns;

  switch (kind) {
    case 'user':
      return [];
    case 'domain':
    case 'system':
      return [columnFactories.createOwnerColumn()];
    case 'group':
    case 'template':
      return [columnFactories.createSpecTypeColumn()];
    case 'location':
      return [
        columnFactories.createSpecTypeColumn(),
        columnFactories.createSpecTargetsColumn(),
      ];
    default:
      return [
        columnFactories.createOwnerColumn(),
        columnFactories.createSpecTypeColumn(),
        columnFactories.createSpecLifecycleColumn(),
      ];
  }
}
