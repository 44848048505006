import {
  CopyTextButton,
  InfoCardVariants,
  StructuredMetadataTable,
} from '@backstage/core-components';
import { useEntity } from '@backstage/plugin-catalog-react';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
} from '@material-ui/core';
import React, { useState } from 'react';
import {
  AwsAccountEntity,
  extractEmail,
} from '@internal/plugin-aws-resources-common';
import './copyTextButton.css';
import CopyableText from '../../../common/CopyableText';

const useStyles = makeStyles({
  gridItemCard: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 10px)', // for pages without content header
    marginBottom: '10px',
  },
  fullHeightCard: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  gridItemCardContent: {
    flex: 1,
  },
  fullHeightCardContent: {
    flex: 1,
  },
});

export interface AboutCardProps {
  variant?: InfoCardVariants;
}

export function AwsAccountMetadataCard(props: AboutCardProps) {
  const { variant } = props;
  const classes = useStyles();
  const entity = useEntity().entity as AwsAccountEntity;
  let cardClass = '';
  if (variant === 'gridItem') {
    cardClass = classes.gridItemCard;
  } else if (variant === 'fullHeight') {
    cardClass = classes.fullHeightCard;
  }

  let cardContentClass = '';
  if (variant === 'gridItem') {
    cardContentClass = classes.gridItemCardContent;
  } else if (variant === 'fullHeight') {
    cardContentClass = classes.fullHeightCardContent;
  }

  const spec = entity.spec;
  const authority = spec.authority;
  const metadata: Record<string, any> = {
    'Account ID': <CopyableText text={entity.metadata.name} />,
    Head: <CopyableText text={authority.head} extractEmail />,
  };
  if (authority.securityAssignee !== undefined) {
    metadata['Security Assignee'] = (
      <CopyableText text={authority.securityAssignee} extractEmail />
    );
  }
  if (authority.delegates !== undefined && authority.delegates.length > 0) {
    const delegates = [];
    for (const delegate of authority.delegates) {
      delegates.push(<CopyableText text={delegate} extractEmail />);
    }
    metadata.Delegates = delegates;
  }
  metadata['Cost Centre'] = spec.costCentre;
  metadata.Region = spec.region;
  metadata.Email = (
    <a href={`mailto:${spec.distributionList}`}>{spec.distributionList}</a>
  );
  if (spec.costMonitorAlerts) {
    metadata[
      'Cost Threshold'
    ] = `${spec.costMonitorAlerts.thresholdDollarValue}$`;
  }
  if (spec.skipStacksets !== undefined && spec.skipStacksets.length > 0) {
    metadata['Skip Stacksets'] = spec.skipStacksets;
  }

  return (
    <Card className={cardClass}>
      <CardHeader title="Metadata" />
      <Divider />
      <CardContent className={cardContentClass}>
        <StructuredMetadataTable metadata={metadata} />
      </CardContent>
    </Card>
  );
}
