/***/
/**
 * Common functionalities for the aws-account-creation plugin.
 *
 * @packageDocumentation
 */

export const AWS_ACCOUNT_CREATION_PLUGIN_ID = 'aws-account-creation';

export type AwsAccountForm = {
  agreement?: boolean;
  name?: string;
  description?: string;
  region?: string;
  headOfTech?: string;
  teamDl?: string;
  adRoleGroup?: string[] | string;
  datadog?: boolean;
  subnets?: boolean;
  natGateways?: boolean;
  costCentre?: string;
  supportRoleGroups?: boolean;
};

export type AwsAccountCreationResponse = {
  error?: string;
  issueUrl?: string;
};

export type AdRoleGroupsResponse = {
  user: string[];
  rest: string[];
};
