/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    marginLeft: '-10px',
  },
  st0: { fill: '#00E2FF' },
});

const LogoIcon = () => {
  const classes = useStyles();

  return (
    <svg
      version="1.1"
      id="Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 1000 1000"
      className={classes.svg}
      // style="enable-background:new 0 0 800 800;"
      xmlSpace="preserve"
    >
      <g>
        <path
          className={classes.st0}
          d="M437.705,389.6783l0.0135-0.0047c-0.0221,0.0074-0.041,0.0097-0.0627,0.0167
		C437.6733,389.6845,437.6872,389.6845,437.705,389.6783z"
        />
        <path
          className={classes.st0}
          d="M439.5851,400.8669c-0.0294-0.0364-0.1068-0.1559-0.147-0.2132c0.1931,0.3184,0.4655,0.7642,0.6783,1.1228
		C439.9689,401.4979,439.7843,401.1625,439.5851,400.8669z"
        />
        <path
          className={classes.st0}
          d="M714.6907,321.9733c-10.5207-32.9249-24.7361-64.9262-44.2809-96.0333
		c-13.435-21.0082-28.5457-42.2523-48.5936-60.5014c-10.3478-9.7202-21.7739-18.9354-33.6887-27.6016
		c-12.0579-8.5296-25.0905-16.4127-38.5685-22.7625c-18.884-8.951-38.0132-15.9678-57.1406-20.1368
		c-24.798-5.2694-48.9449-7.1398-72.4127-6.7366c-23.7418,0.1745-47.1117,3.1155-70.042,7.5345
		c-67.9488,13.4153-131.4554,45.3697-183.561,93.6669c-17.202,16.2742-32.6181,34.7124-45.937,54.8466
		c-13.4675,20.3783-23.8868,42.2148-32.4172,64.5188c-18.2537,47.8406-23.4906,99.2831-18.0355,149.8836
		c2.4101,20.8128,6.92,41.5025,13.3971,61.7318c7.0152,20.0974,15.4416,39.8929,27.0491,58.1632
		c30.3855,49.1461,74.2424,87.2162,124.109,108.9758c20.5183,8.9843,41.9668,15.806,63.8939,19.8145
		c23.5053,4.3033,47.7234,5.4943,71.7363,3.7283c10.968-0.5467,23.9356,0.3316,31.4008-8.7819
		c0.5494-0.6771,2.4117-0.3919,2.6554-1.9071c-1.1348,0.0612-2.2488-0.0731-4.2809-0.159c3.0934-1.0869,4.6342-2.8334,6.1261-4.5382
		c-1.5233-0.9797-3.5956,0.5049-4.4523-1.1236c-0.1819-1.3426,2.4059-1.6765,2.0739-3.1313
		c-1.9675-0.1552-3.9427-0.6171-6.4318-1.0578c1.0962-0.9476,2.3455-1.5593,3.6525-2.0375
		c24.8773,8.3884,49.6042,10.1632,72.4572,9.3522c34.9171-1.2691,67.5983-11.187,98.079-23.5831
		c2.7293-1.124,6.7177-2.4306,7.488-4.6635c1.7369-4.7855,6.3845-6.1261,10.0599-6.6349
		c5.7241-0.6961,10.5168-3.4838,15.1367-6.4244c21.2017-13.1962,40.2972-29.2104,57.0543-47.4459
		c13.6505-14.8329,25.5529-31.3528,35.8922-48.965c10.2464-17.7093,18.3558-36.9547,24.4332-57.0164
		c1.6328-6.0058,3.1689-12.0518,4.5761-18.0881c1.0857-5.8897,2.2859-11.8242,2.9863-17.6818
		c1.7013-11.7805,2.4171-23.3726,2.8218-34.7918C733.0795,392.0076,725.0663,355.2921,714.6907,321.9733z M275.9384,398.608
		c0.445-3.4749,0.8342-6.9645,1.4362-10.4116l2.2619-10.263l0.8156,0.1996l-2.2496,10.2073
		c-0.5997,3.4281-0.9851,6.8984-1.4281,10.3547L275.9384,398.608z M357.9269,349.8361c2.2182-1.5357,4.537-2.91,6.8353-4.236
		c-4.2318,3.283-7.7318,7.2203-12.4669,10.0003C353.7266,353.2456,355.8801,351.5556,357.9269,349.8361z M358.0515,306.6671
		c2.7258-0.1671,6.412-4.2058,8.2499,0.1753c-5.377-2.941-7.3089,4.6059-11.565,4.5161
		C353.2383,308.5487,355.9962,306.8827,358.0515,306.6671z M308.0086,347.8647c5.0814-9.3704,11.5522-18.3032,21.0453-25.5815
		C322.5664,332.3621,313.0675,338.1937,308.0086,347.8647z M268.9421,478.5347c1.9388,5.88,3.873,11.83,6.0696,17.38
		C270.0212,491.3762,270.2058,484.585,268.9421,478.5347z M585.6467,432.2982c-0.2457,5.6428-1.0381,11.1591-2.0929,16.4804
		c-0.4256,2.7375-1.1019,5.2323-1.6382,7.7674c-0.4682,2.5606-0.8845,5.1499-1.519,7.7349
		c-2.2205,12.0812-8.3486,23.3931-15.3928,34.0698c-1.1785,1.7241-2.6922,3.2489-4.2418,4.7374
		c-1.6889,1.3879-3.4684,2.6956-4.994,4.2c1.2629-1.8305,2.7383-3.4711,4.0441-5.2261c1.1917-1.8375,2.3192-3.7071,3.1329-5.7484
		c-6.1768,8.5598-13.6579,16.326-21.8803,23.1138c1.5729,1.7422,1.0002,4.3276-0.4376,3.813
		c-3.9888-1.4711-5.4142,1.2231-7.4188,2.741c-3.1874,2.5587-6.7904,4.4023-10.0742,6.8047
		c-3.4985,1.9671-6.9146,4.1845-10.5064,5.9457c-9.6234,5.0942-20.1116,7.7972-30.3824,9.1069
		c-14.1113,2.1284-28.2706,1.8286-39.049-1.4362c-2.9015-0.8242-5.2586-1.6943-6.9166-3.9772
		c-1.1878-1.7195-2.2786-3.1592-3.9694-3.6931c-2.3385-0.6369-4.1574-2.1478-5.4892-4.1249
		c-3.8522-5.6874-10.7362-12.7532-16.5021-23.1424c-5.0729-9.1123-11.002-20.7257-15.7913-30.8827
		c-4.8709-10.3694-9.0558-20.451-12.1984-30.0025c-3.1313-9.5341-5.1642-18.5156-5.9032-26.3291
		c-0.3362-3.8862-0.4933-7.5457-0.2302-10.7981c0.1188-1.6351,0.1478-2.9986,0.4872-4.2983
		c0.1509-1.3357,0.3857-2.655,0.8218-3.9303c0.272-1.7609,1.093-3.3577,1.5279-5.0702c2.5719-6.2812,6.8528-12.0564,12.821-15.2191
		c0.3719-0.2004,0.7584-0.4786,0.7309-1.0814c-0.0634-2.1021,1.8878-4.9355,3.8715-3.8793c1.9183,0.9205,3.0025,0.4941,4.231,0.2577
		c5.7183-0.5347,11.1789-4.0309,15.4056-2.0549c3.3329,1.4474,7.2071,2.5699,10.4871,4.8903
		c0.2372,0.2163,0.202,1.0041,0.8589,0.3149c1.6425-1.6827,1.355-0.2174,1.059,0.9317c1.1035-0.9607,0.8837-1.9036,1.9644-3.1062
		c-0.4465,2.2608-0.3447,3.1638,1.4161,2.3397c0.4423-0.2128,0.7839-0.0666-0.1265,1.2269
		c-1.0815,1.543-0.4941,0.9619-0.1397,1.4204c0.3811,0.4744,1.5272,0.3219,0.3099,2.0402c-1.1128,1.3813-0.9449,1.7675-0.13,1.3828
		c2.5757-1.1209,1.1081,0.5518,1.3217,0.7576c0.0325,0.1335,0.0193,0.2852,0.0344,0.4186
		c-0.0886,0.1497-0.2337,0.2821-0.2213,0.3649l0.0058,0.0298l0.0074,0.0135l0.0023,0.0031l0.0012,0.0016l0.0008,0.0008
		l2.0008-0.6469c-0.0925,0.1025-0.1683,0.1822-0.3382,0.3618l-1.3066,1.7632c-0.496,0.5595-1.0571,0.7897-0.9058,0.8687
		c2.6287,1.7996,5.3944,4.6659,6.802,6.7606c-0.3552-0.551-0.698-0.9967-1.076-1.4486c0.0074,0.0089,0.012,0.0143,0.0193,0.0232
		c0.4504,0.4716,0.9959,1.3376,1.2985,1.7643c-0.1513-0.2496-0.2538-0.4221-0.1737-0.3099
		c0.1965,0.1981,0.5134,0.2325,1.8112,0.8447c-1.0017,0.0538-1.2203-0.0468-0.4883,1.3167
		c1.4227,1.4784,1.2304-0.4186,4.2476-0.6129c-1.1074,0.9669-2.0054,1.4811-2.515,2.0124c-0.5753,0.373-0.8613,0.4662-1.0048,0.7057
		c1.5794,2.4314,4.4871-0.5587,7.1576-5.0133c-1.4788-0.7468-0.7626,0.4136-2.9603-0.8485
		c5.0327-0.1053,6.3227-3.3654,6.3734-6.0882c0.0847-2.9146-0.734-5.0226,1.2087-7.8436c0.4535,0.8431,1.037,1.2664,1.2502,1.9594
		c1.1728-0.0306,2.5092-0.8934,3.9535-0.5355c0.2546,0.7479,0.1966,1.5496-0.2132,2.4101c-0.3111,0.828-0.7576,1.6363-1.3353,2.4697
		c-0.4635,0.8121-0.9104,1.6065-1.2467,2.4627c-0.1965,0.8543-0.3068,1.7446,0.0097,2.828
		c-3.0199-1.5933-0.9549-2.0236-2.3231-2.8508c0.1834,1.2513-1.7195,1.9009,1.0203,3.7206c1.6328,1.2149,1.1696,2.631-0.3138,3.5291
		c-1.7183,1.3542-4.58,1.5748-1.4985,5.3077c0.1749,0.224-0.1706,0.3328-0.4713,0.4264c2.1764,0.2298,2.8237-1.0041,2.8346-2.3997
		c0.2136-2.1176,2.7661-3.3279,2.9611-5.6467c3.2981,1.0992,2.3153,1.8866,2.2236,2.6585
		c-0.2093,0.9704-1.5222,1.5833-0.3753,2.9356c1.6173,2.0623,0.2654,2.8879-1.6866,3.1081c-0.91-0.8067,1.0706-0.8195-0.8013-2.0646
		c0.9681,2.6984-3.9616,1.9864-0.3625,6.2213c0.6473-0.4221,0.5212-1.2977,2.0406-1.0853c-1.7059,2.3803-3.9001,4.157-5.9287,5.3697
		c-2.0894,1.1453-3.685,2.2658-5.184,3.8642c0.7,0.1966,1.3484,0.1687,1.9597,0.036c6.2097-1.9919,13.4675-9.2555,16.9738-18.9443
		c0.527-1.2927,0.686-2.6926,1.1573-4.0255c0.1938-1.3561,0.7293-2.6868,1.3268-3.981c0.4453-0.9804,1.5747-1.8963,2.3687-2.9359
		c0.6489-1.0017,0.9537-2.1354-0.0696-3.2795c-0.8118,0.4198,0.5533,1.5577-1.8452,1.6394
		c-0.019-2.3169-0.4774-4.1721,3.5709-6.4438c1.521,0.7038,0.0929,2.3927,2.6585,3.1971c-1.2045-1.6285,1.1441-4.1737-2.679-4.294
		c-1.6668-0.0403-1.8742-0.8408-2.1977-1.6049c-0.4446-0.7742-0.9619-1.4858-2.9642-1.0342
		c2.4453-2.703,0.8021-4.5417,0.5947-6.6542c2.503-0.78,1.1542,2.4252,4.058,1.1368c-4.7699-17.3812-22.362-32.9206-42.4396-38.3476
		c-19.866-5.7055-43.0552-1.562-62.34,9.4718c5.0385-4.1002,10.6674-8.2003,17.1092-9.4164
		c8.8871-1.803,17.4493-4.8171,26.3074-4.4523c6.494,0.2488,12.8221,1.1627,18.9683,2.8725
		c2.4724,0.6937,2.9773-0.2132,3.365-2.9429c-3.8688-1.8197-8.0518-2.2433-11.3065-6.1868
		c3.4807-1.0749,6.8678,1.0431,10.6863,0.7433c-0.9924-4.6334-5.8545-2.7672-8.3369-4.9112
		c3.2679-2.2589,2.9785-4.9421,0.8868-7.5902c1.2807-1.2362,2.7138,0.941,4.2077-1.1592
		c-20.521-4.4016-41.9192-1.3859-61.0307,6.8578c9.5952-5.6548,20.3272-9.7631,31.3192-10.0874
		c0.7839-1.6266-0.6848-1.5604-1.018-2.1772c2.7529-2.3393,4.3946-1.6928,4.5451,1.7141c5.6405-1.2648,11.0473,0.2906,16.506-0.5638
		c-0.7653-2.1814-3.6564,0.0611-4.2487-2.5231c2.4372,0.2058,4.8322,0.4082,7.206,0.609c2.3494,0.3927,4.6775,0.782,7.0059,1.1712
		c-10.8186-4.0344-22.875-4.3439-34.2787-2.4422c-11.4203,1.8534-22.3488,5.6877-33.2986,9.7546
		c18.6348-9.9926,40.1374-14.4905,61.5457-12.1717c21.3688,2.5657,41.1407,11.8119,56.7521,25.8435l0.9077-0.9801
		c-14.1508-13.1015-31.85-22.1759-51.2366-26.0443c-19.5402-3.6935-39.8596-1.9744-58.4209,4.7297
		c-0.1467-1.5589,1.0818-3.4869-0.9588-4.9282c-13.1208,3.442-23.9696,12.4282-36.5674,18.1736
		c7.0361-5.0454,13.9693-10.2243,21.6377-14.2676c3.069-1.4506,6.3745-2.6616,9.5016-4.0154
		c3.2157-1.1666,6.2697-2.4635,8.9053-4.2762c3.4919-2.087,7.993-0.077,10.1431-3.8394c5.3522,2.1784,10.3176-1.1871,15.6578-1.4684
		c10.0034-0.6736,20.2409-0.8938,30.2845,0.9356c16.9908,3.0837,32.8305,9.3282,47.1577,18.5133
		c4.4043,2.8481,7.7032,6.9858,13.7728,9.3882c-9.0539-9.064-19.4903-16.1346-30.6842-21.5456
		c-11.2056-5.4293-23.166-9.3275-35.9684-11.7233c4.2244-2.0321,8.768,0.2147,12.8643-0.9205
		c-8.8357-2.1261-18.0842-3.1008-27.3409-3.0938c0.5359,2.4349,3.4799,1.4931,3.5883,3.2122
		c-4.472,0.9545-8.7254,0.3185-12.9351,0.2848c-0.2132-1.119,2.2449-2.4074,0.431-2.6577
		c-3.7643-0.4496-7.9988-0.8884-11.8347-0.0372c-2.2867,0.4539-1.8425,2.5951-0.9897,4.1636
		c-4.4345,0.9143-8.1636,3.3519-12.8875,3.2567c2.4542-2.3939,5.9845-2.5448,9.2822-4.3377
		c-5.8076-1.4281-11.2888-2.1757-15.6633,3.0292c-0.4206,0.5161-1.4134,1.0068-2.1478,1.1414
		c-3.8201,0.6272-7.4946,1.7922-11.0678,3.3c-3.6413,1.3747-7.0961,3.2118-10.583,5.1178
		c-8.3923,4.5362-16.6781,9.8823-24.0757,16.1867c-13.96,11.8896-25.1799,27.135-32.7194,43.9823
		c-0.556,1.2428-1.2316,2.9019-2.8911,2.2762c9.3019-23.4237,25.7913-43.4127,45.7025-58.4244
		c-22.3999,14.5803-40.0782,36.6912-49.9153,61.9601c-1.4161,4.0298-2.8121,8.0831-4.1095,12.1655
		c-1.0342,4.1335-2.0735,8.2867-3.124,12.4843c-0.7851,4.2159-1.4533,8.4697-2.125,12.7277
		c-0.5332,4.2495-0.8179,8.424-1.2029,12.5052c-0.2902-4.0797-0.4964-8.3044-0.3211-12.6294
		c0.3146-4.335,0.7638-8.6867,1.4111-12.9985c0.8617-4.2747,1.8506-8.535,2.9158-12.7614c1.276-4.1706,2.6833-8.3045,4.0916-12.4213
		c4.3331-11.0497,9.6625-21.5665,15.4315-32.0125c-8.2944,8.6801-13.9666,19.6335-18.3272,30.9222
		c-2.2418,6.5916-4.4639,13.1847-6.2824,19.9128c-1.6409,0.1509-2.7011-1.8232-4.2975-0.534
		c-4.0739,11.3027-5.3561,24.0671-5.6246,35.2913c-0.0275,11.3704,0.7054,22.5802,1.497,33.1106
		c-3.2416-18.7087-4.4828-38.8165-0.9708-61.105c-2.2387,5.0594-2.4152,11.5631-6.414,14.6236
		c-0.3424,0.2604-0.2983,1.8835-0.4225,2.8763c-0.4504,4.0255-1.1136,8.0719-1.2447,11.9256
		c-0.7572-7.8274-0.4806-16.2587,0.9805-25.0124c1.1677-8.6975,3.4629-17.303,5.6537-26.1592
		c-3.5357,2.7986-2.9197,7.685-5.2095,9.8355c-0.3927-6.3919,2.6446-12.3493,4.7471-19.2353
		c-2.0224,1.7659-2.3799,4.9065-3.7589,5.5078c-0.5808-3.023,0.7611-5.6846,2.0855-8.2905
		c1.3345-2.2987,2.6461-4.5692,2.6133-7.2834c-2.0247,0.6671-2.4929,2.6934-3.1719,4.8152c-0.2662,0.7386-0.4945,1.3705-0.717,1.988
		c-0.8237,2.4434-2.0035,4.8755-3.3449,4.946c-3.5682,0.1749-4.2151,4.5122-5.2462,7.7465
		c-1.4583,5.2145-2.7394,10.5022-3.9404,15.8029c-0.9855,5.3391-1.7512,10.7409-2.4604,16.1419
		c-0.5208,5.3801-0.7665,10.5656-1.0234,15.6191c-0.0669,4.9824,0.0468,9.8931,0.1648,14.8488
		c0.0457,1.5086,0.1045,3.4374,0.1575,5.1773c-1.6761-5.5372-1.3813-11.9964-1.478-18.1728
		c0.248-6.3714,0.2856-13.0125-0.5498-20.4371c-2.6434,6.2971-1.5519,11.4582-2.1617,16.1067
		c-0.7011,6.2491-0.8404,12.191-1.0211,18.3732c0.1525,5.832,0.3633,11.9129,1.2335,17.5259
		c2.1888,15.5924,4.1319,29.9611,8.8473,43.1496c0.7994,2.1288,0.5034,3.8878,0.2658,5.6811
		c-0.5595-0.8853-1.1348-1.7388-1.6901-2.6279c-1.9013-4.3857-6.0653-7.6355-7.986-12.2081
		c-1.1008-2.3606-2.2039-4.7162-3.0919-7.1971c-0.8005-2.5227-1.6997-5.0203-2.9874-7.4555
		c-4.5266-9.6873-5.976-20.6185-7.3279-31.5544c-0.2291-1.6541-0.4461-3.2246-0.6612-4.7804
		c-0.0406-1.6305-0.0805-3.2366-0.1203-4.8446c0.0074-3.2343-0.4128-6.4751-0.2898-9.7357
		c-0.0994-5.2946,0.1931-10.9169,1.634-16.2626c2.1145-9.7488,3.9384-19.5731,6.5749-29.2194
		c1.5388-4.1547,2.0584-8.7823,4.1783-12.689c2.7371-4.9266,2.4445-10.9719,4.9189-15.9597
		c0.8555-1.8479,1.8441-3.6626,2.9731-5.3569c1.2029-1.6421,2.3807-3.2803,3.3066-5.0443c0.9058-1.6827,1.7582-3.3898,2.7641-5.0303
		c1.1283-1.6053,2.2403-3.1874,3.3515-4.7676l3.3929-4.7405l3.8549-4.4349c6.5351-7.9752,14.2464-15.1076,21.9561-22.2606
		c8.1706-6.7026,16.4003-13.5104,25.5908-19.195c1.622-1.0513,3.5515-1.8483,5.182-3.0563c1.6889-1.1244,3.023-2.7498,3.3933-5.7272
		c0.1621-1.2827,2.3567-1.7218,4.2077-2.9565c-0.8501,2.4844-3.531,1.3701-3.3294,4.3799c2.2917-0.975,4.7726-1.3798,7.0241-2.1424
		c2.3049-0.6775,4.3087-1.841,5.6304-4.679c-1.6251-1.0888-2.5618,0.0019-3.8684,0.2948c4.4925-3.2683,9.2764-5.4486,14.5714-6.1776
		c-3.2087,1.331-6.761,2.0507-8.9684,5.7342c6.5803,0.0445,21.6609-5.8092,22.728-9.4513
		c-3.3171,0.1617-5.668,3.6823-10.1837,1.4885c18.4429-8.7598,38.2164-11.2284,57.2261-15.9844l-0.1656-1.43
		c-2.4353,0.1613-4.8725,0.3169-7.2954,0.7348l-0.0646-1.2687c4.4724,1.598,8.8392-3.2296,13.416,1.2362
		c0.835,0.8713,3.0872,0.5057,3.7864-1.4544c0.8485-2.4562,2.4391-2.0383,3.7013-1.4738c1.6289,0.7274,3.0203,0.5599,4.633,0.1497
		c6.4163-1.728,13.0117-0.0526,19.4001-0.3072c3.3867-0.0886,7.1634,1.6359,10.2952,1.7787
		c3.5213,0.1853,6.8767,1.0664,10.2383,1.5941c0.6152,0.1029,1.0613-0.1118,1.7597-0.599c2.1718-1.5438,4.9286-2.0542,6.3172,0.2681
		c1.0292,1.7268,2.0023,1.9686,3.3453,1.48c1.6197-0.5943,3.0505-0.3703,4.4337,0.0538c5.3468,1.6042,10.3056,3.858,15.2926,5.6687
		c3.5937,1.3933,6.8647,3.3151,10.8012,3.9466c2.9851,0.337,4.9499,2.5119,7.5151,3.7144c0.8156,0.3525,0.9801,1.194-0.2376,2.4863
		c-2.2031,0.2527-2.78-2.7065-6.183-1.9849c6.1423,2.4272,10.4066,6.6318,13.2032,12.1671
		c1.0516-1.1418,0.4759-2.6094,1.7113-2.2341c1.2494,0.3807,3.3763,1.463,2.59,2.5069c-1.8494,2.4619-0.0812,2.1165,0.5997,2.7328
		c5.6985,4.8702,11.1673,10.3977,16.5261,16.3206c9.7894,11.3104,17.837,24.9381,24.326,39.7606
		c7.2021,16.0126,15.1711,33.0595,20.588,50.407c0.2109,0.7185,0.6168,1.7299,0.214,2.2186
		c-2.1985,2.6674-0.9542,5.1035-0.5015,7.5201c1.3244,6.2437,2.2368,12.3868,3.7725,18.3918
		c0.7866,3.2408,0.5607,6.3799-0.7336,9.7477c-1.302,3.5237,1.235,7.3194,2.6516,11.0655
		C586.8801,422.421,585.8575,427.5446,585.6467,432.2982z M401.1698,335.56c5.7786-1.374,11.6265-0.1559,15.1688-0.3308
		C412.7023,334.9583,407.157,336.2243,401.1698,335.56z M436.7226,397.2747c0.012,0.0135,0.0132,0.0151,0.0244,0.0275
		c0.0251,0.0232,0.0627,0.0646,0.0584,0.0464c0.065-0.0607,0.1188-0.0928,0.8334,0.3452c-0.4751-0.2325-0.5409-0.1134-0.5049-0.0012
		c0.2387,0.284,0.5374,0.6396,0.8462,1.0071c-0.284-0.3335-0.6156-0.7034-0.9654-1.0938c-0.0948-0.1037-0.185-0.2151-0.2817-0.315
		c0.0062,0.0062,0.0073,0.0054,0.0132,0.0105C436.7369,397.291,436.7319,397.2852,436.7226,397.2747z M437.7623,389.6617
		l-0.024,0.0081c-0.012,0.0042-0.0213,0.0046-0.0333,0.0085l-0.1892,0.0646c-0.0901,0.0247-0.1888,0.0522-0.2797,0.077
		l0.2952-0.0956c0.043-0.0132,0.0813-0.0201,0.1246-0.034c-0.0325,0.0108-0.0584,0.0124-0.0905,0.0228l0.5699-0.1842l-0.0023,0.0008
		l-0.0043,0.0016l-0.0692,0.0255l-0.3409,0.1168C437.7336,389.6687,437.7468,389.6667,437.7623,389.6617z M452.7465,376.3439
		c1.3593,2.3196,3.5879,4.0162,5.1565,6.1969c0.7773,0.9139,1.1948,1.8235-2.2581,3.0714
		C454.4888,383.4795,454.6846,379.4246,452.7465,376.3439z M464.3974,361.0564c2.0588,2.072,3.0187,4.8005,5.6931,6.373
		c0.5668,0.351,0.1741,1.1352-0.443,1.6255c-0.7556,0.5993-2.1973,1.0571-2.1753,0.4171c0.053-1.3972-3.9756-0.2643-1.2037-3.5082
		C467.6232,364.3467,464.6849,363.0649,464.3974,361.0564z M411.3059,318.0225c-6.5734,0.402-12.3934,1.9729-18.788,1.3832
		c4.8667-1.786,8.3892-6.9653,14.7165-6.2782C410.7882,313.6743,412.2403,314.4771,411.3059,318.0225z M418.0851,323.5794
		c-3.5655-0.6864-5.5209,5.75-9.0802,0.1455c-2.3954,3.9272-5.0779-1.1465-7.8769,0.2813
		C406.0674,320.9031,411.9796,319.9609,418.0851,323.5794z"
        />
        <path
          className={classes.st0}
          d="M437.5533,398.2076c-0.5653-0.6349-0.3126-0.3513-0.8063-0.9054c-0.0004-0.0004-0.0004-0.0004-0.0008-0.0008
		c0.1041,0.1172,0.1714,0.1958,0.2685,0.3045C437.1962,397.8044,437.3816,397.9975,437.5533,398.2076z"
        />
        <path
          className={classes.st0}
          d="M440.1163,401.7765c0.123,0.2325,0.224,0.433,0.2511,0.4678
		C440.3991,402.2621,440.2776,402.0489,440.1163,401.7765z"
        />
        <path
          className={classes.st0}
          d="M451.6914,406.6258c0.0623-0.0244,0.1521-0.0557,0.2349-0.0816c-0.1169-0.0124-0.1865,0.0197-0.3126-0.0015
		L451.6914,406.6258z"
        />
        <path
          className={classes.st0}
          d="M449.2755,328.4979c-2.6856-1.3352-5.2238-3.3224-8.3586-3.8672c-1.7756-0.4001-3.3573-0.97-4.7758,1.8893
		c11.1688,2.9402,19.984,9.7058,29.8415,14.9246C462.2755,335.1425,455.4897,331.8548,449.2755,328.4979z"
        />
        <path
          className={classes.st0}
          d="M435.3022,330.1129c0.7115,3.514,12.4743,9.5557,20.5733,13.4369
		C451.6848,336.1856,444.0404,331.3155,435.3022,330.1129z"
        />
        <path
          className={classes.st0}
          d="M261.579,336.9103l4.0012-9.8939l-0.975-0.361c-2.2739,4.1811-4.5479,8.3772-6.6129,12.6639
		c-1.9195,4.3563-3.7508,8.7618-5.5237,13.1773l-0.6833,2.0476c0.3807-0.7398,0.7019-1.3639,0.9932-1.9307
		c2.756-4.968,5.0087-10.6809,9.7813-12.073c0.9619-0.2612,2.626-3.0586,3.5999-4.8694c3.6866-7.127,7.9729-13.9438,12.5699-20.521
		c0.9216-1.2799,2.5834-2.9386,1.1581-4.3575C272.7254,318.6299,266.8427,327.56,261.579,336.9103z"
        />
        <path
          className={classes.st0}
          d="M296.1364,326.6159l1.6479,1.1271c11.4934-15.4679,25.9948-28.7489,42.587-38.6316
		C322.3168,296.7303,307.9142,311.1481,296.1364,326.6159z"
        />
        <path
          className={classes.st0}
          d="M442.805,318.5382c6.1544,0.9131,10.8508,5.3697,17.0086,6.5025
		C455.523,320.2078,450.2629,314.481,442.805,318.5382z"
        />
        <path
          className={classes.st0}
          d="M469.4784,324.5916c-10.2518-6.9429-21.1358-14.547-34.9875-16.0274
		C447.3943,312.8861,458.8444,317.7922,469.4784,324.5916z"
        />
        <path
          className={classes.st0}
          d="M576.5436,377.4644c1.2273,9.1038,4.6342,16.5814,5.3174,24.432
		C581.2369,394.0822,580.5934,385.8463,576.5436,377.4644z"
        />
        <path
          className={classes.st0}
          d="M263.7586,361.978c1.5183-2.7672,2.9638-0.2975,5.4807-2.8969l-0.2585-0.3668
		c-3.6432-5.6815,4.1675-12.641,4.5997-20.095c-4.1226,6.3014-6.4689,12.5358-8.6024,18.5876
		C264.5974,358.6958,264.195,360.2705,263.7586,361.978z"
        />
        <path
          className={classes.st0}
          d="M581.1127,451.9421l0.1354,0.0186c1.1425-5.3306,2.1931-10.7726,2.6898-16.4456l-1.1967-0.0542
		C582.605,441.1045,581.9055,446.5539,581.1127,451.9421z"
        />
        <path
          className={classes.st0}
          d="M470.4968,296.4823c-7.7949-4.1041-16.0746-7.1855-24.6026-9.4981
		C454.0137,290.3411,461.8279,293.871,470.4968,296.4823z"
        />
        <path
          className={classes.st0}
          d="M447.6686,409.2193c1.0532-0.3873,1.8259-1.2362,4.2743-0.9692c0.6694,0.0422,0.2817-0.8551-0.2693-1.6161
		C448.6622,405.9789,447.5556,406.7275,447.6686,409.2193z"
        />
        <path
          className={classes.st0}
          d="M451.7007,406.637l-0.087-0.0944c0.022,0.0294,0.0383,0.0611,0.06,0.0913
		C451.684,406.6362,451.6902,406.6347,451.7007,406.637z"
        />
        <path
          className={classes.st0}
          d="M468.4794,402.0098c2.4438-2.3227,1.7675-5.5651,2.272-8.3482
		C468.421,396.3028,468.2902,398.9176,468.4794,402.0098z"
        />
        <path
          className={classes.st0}
          d="M265.7257,380.6693c-0.0108,0.0426-0.0313,0.0801-0.0418,0.123l-0.0247,0.0522
		c0.0085-0.007,0.0151-0.0027,0.0236-0.0097c0.0128-0.0356,0.0197-0.0739,0.0325-0.1095
		C265.7168,380.7061,265.7241,380.6887,265.7257,380.6693z"
        />
        <path
          className={classes.st0}
          d="M275.7759,353.4097c-0.732,2.395-1.7152,4.6287-2.7622,6.8229c-2.7583,6.5912-6.6484,12.8852-7.288,20.4367
		c1.7903-7.2006,6.4081-13.0396,8.489-19.9844C275.0055,358.4427,275.5948,356.0728,275.7759,353.4097z"
        />
        <path
          className={classes.st0}
          d="M577.6951,464.0813c1.415-0.8942,2.1145-2.0503,2.4539-3.2965c0.2341-1.2699,0.0313-2.6446-0.1122-3.9791
		C579.4846,459.2704,578.655,461.6878,577.6951,464.0813z"
        />
        <path
          className={classes.st0}
          d="M470.0352,334.3706c2.7096,1.7856,4.619,3.5229,6.6523,5.1267
		C474.8175,337.948,476.4286,331.1723,470.0352,334.3706z"
        />
      </g>
    </svg>
  );
};

export default LogoIcon;
