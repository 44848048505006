import React, { useEffect, useState } from 'react';
import {
  DefaultEntityFilters,
  useEntityList,
} from '@backstage/plugin-catalog-react';
import {
  FormControl,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { OrphanedAccountsFilter } from './OrphanedAccountsFilter';

type CustomFilters = DefaultEntityFilters & {
  orphaned?: OrphanedAccountsFilter;
};

export const OrphanedAwsEntityPicker = () => {
  const { updateFilters } = useEntityList<CustomFilters>();

  // Toggles the value, depending on whether it's already selected
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    updateFilters({
      orphaned: checked ? new OrphanedAccountsFilter() : undefined,
    });
  }, [updateFilters, checked]);

  return (
    <FormControl component="fieldset">
      <Typography variant="button">Orphaned</Typography>
      <FormGroup>
        <FormControlLabel
          key="authority-filter"
          control={
            <Checkbox checked={checked} onChange={() => setChecked(!checked)} />
          }
          label="Has stale authority values"
        />
      </FormGroup>
    </FormControl>
  );
};
