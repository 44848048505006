/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 70,
    enableBackground: 'new 0 0 352.7 126.8',
  },
  st0: { fill: '#00FFD0' },
  st1: { fill: '#EF953B' },
  st2: { fill: '#FFFFFF' },
  st3: { fill: '#00DFFF' },
  st4: { fill: '#00DFFF' },
});

const LogoFull = () => {
  const classes = useStyles();

  return (
    <svg
      version="1.1"
      id="Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 352.7 126.8"
      xmlSpace="preserve"
      className={classes.svg}
    >
      <path
        className={classes.st2}
        d="M219.1,67.4l-0.1-0.3l0.1,0.1L219.1,67.4L219.1,67.4z"
      />
      <path
        className={classes.st2}
        d="M219.1,67.4l-0.1-0.3v0.1L219.1,67.4L219.1,67.4L219.1,67.4z"
      />
      <g>
        <path
          className={classes.st2}
          d="M124.5,41h22.7c10.8,0,16.2,7.6,16.2,15.2c0,7.7-5.6,15.3-16.4,15.3h-15.7v22.3h-6.8V41z M131.3,47.2v18.1
          h15.6c6.3,0,9.4-4.6,9.4-9.2s-3.1-8.9-9.2-8.9H131.3z"
        />
        <path
          className={classes.st2}
          d="M185.9,54.7c11.4,0,18.7,9,18.7,20c0,11.1-7.3,20-18.7,20c-11.5,0-18.7-8.9-18.7-20
          C167.1,63.7,174.4,54.7,185.9,54.7z M185.9,88.7c7.5,0,12.3-6.5,12.3-14s-4.7-13.9-12.3-13.9c-7.6,0-12.4,6.4-12.4,13.9
          S178.4,88.7,185.9,88.7z"
        />
        <path
          className={classes.st2}
          d="M233.5,62c-1.5-0.6-3.1-1-4.4-1c-4.7,0-8.5,3.5-8.5,11.3v21.4h-6.2V55.7h5l1.3,5.4c2.2-4.1,5.6-5.8,9.4-5.8
          c1.1,0,2.3,0.2,3.5,0.5V62z"
        />
        <path
          className={classes.st2}
          d="M254.2,89c1.1,0,2.4-0.5,3.5-1.1v6c-1.6,0.5-3.2,0.8-5,0.8c-4.8,0-9.3-2.8-9.3-11.9V61.3h-6.5v-5.6h6.5v-8.5
          l6.2-2.4v10.9h8v5.6h-8v22C249.6,87.3,251.7,89,254.2,89z"
        />
        <path
          className={classes.st2}
          d="M299.9,88.5v5.1c-1.1,0.7-2.5,1.1-4.1,1.1c-3.9,0-6.8-2.9-6.8-7.4c-2.2,4.7-7.1,7.4-12.3,7.4
          c-6,0-11.8-3.7-11.8-11.5c0-9.3,8.3-10.9,15.6-12.4c5.6-1.1,8-1.7,8-4.8c0-3.7-3.4-5.7-7.7-5.7c-4.4,0-7.9,2.2-9.6,6l-5.3-2.6
          c2.1-4.7,6.9-8.9,15.3-8.9c8,0,13.8,4,13.8,13.7v16.8c0,2.9,1.4,3.9,2.9,3.9C298.4,89,299.3,88.8,299.9,88.5z M281.1,75.6
          c-5.1,1.1-9.9,2.3-9.9,7.2c0,4.2,3.2,6.1,6.9,6.1c5.2,0,10.5-3.8,10.5-11.4v-4.6C286.2,74.5,284,75,281.1,75.6z"
        />
        <path className={classes.st2} d="M308.1,39.9h6.2v53.9h-6.2V39.9z" />
      </g>
      <g>
        <path
          className={classes.st3}
          d="M62.3,68.1C62.3,68.1,62.4,68.1,62.3,68.1C62.4,68.1,62.3,68.1,62.3,68.1L62.3,68.1z"
        />
        <path
          className={classes.st3}
          d="M60.9,67.4C60.9,67.4,60.9,67.4,60.9,67.4c0,0,0,0.1,0.1,0.1C60.9,67.5,60.9,67.4,60.9,67.4z"
        />
        <path
          className={classes.st3}
          d="M60.9,67.5C60.9,67.5,61,67.5,60.9,67.5C61,67.5,61,67.5,60.9,67.5z"
        />
        <path
          className={classes.st3}
          d="M94.3,57.8c-1.3-4-3-7.9-5.4-11.7c-1.6-2.6-3.5-5.1-5.9-7.3c-1.3-1.2-2.6-2.3-4.1-3.4c-1.5-1-3-2-4.7-2.8
          c-2.3-1.1-4.6-1.9-6.9-2.4c-3-0.6-5.9-0.9-8.8-0.8c-2.9,0-5.7,0.4-8.5,0.9c-8.3,1.6-16,5.5-22.3,11.4c-2.1,2-4,4.2-5.6,6.7
          c-1.6,2.5-2.9,5.1-3.9,7.8C16,62,15.3,68.3,16,74.4c0.3,2.5,0.8,5,1.6,7.5c0.9,2.4,1.9,4.8,3.3,7.1c3.7,6,9,10.6,15.1,13.2
          c2.5,1.1,5.1,1.9,7.8,2.4c2.9,0.5,5.8,0.7,8.7,0.5c1.3-0.1,2.9,0,3.8-1.1c0.1-0.1,0.3,0,0.3-0.2c-0.1,0-0.3,0-0.5,0
          c0.4-0.1,0.6-0.3,0.7-0.6c-0.2-0.1-0.4,0.1-0.5-0.1c0-0.2,0.3-0.2,0.3-0.4c-0.2,0-0.5-0.1-0.8-0.1c0.1-0.1,0.3-0.2,0.4-0.2
          c3,1,6,1.2,8.8,1.1c4.2-0.2,8.2-1.4,11.9-2.9c0.3-0.1,0.8-0.3,0.9-0.6c0.2-0.6,0.8-0.7,1.2-0.8c0.7-0.1,1.3-0.4,1.8-0.8
          c2.6-1.6,4.9-3.5,6.9-5.8c1.7-1.8,3.1-3.8,4.4-5.9c1.2-2.2,2.2-4.5,3-6.9c0.2-0.7,0.4-1.5,0.6-2.2c0.1-0.7,0.3-1.4,0.4-2.1
          c0.2-1.4,0.3-2.8,0.3-4.2C96.5,66.3,95.5,61.8,94.3,57.8z M41,67.1c0.1-0.4,0.1-0.8,0.2-1.3l0.3-1.2l0.1,0l-0.3,1.2
          c-0.1,0.4-0.1,0.8-0.2,1.3L41,67.1z M51,61.2c0.3-0.2,0.6-0.4,0.8-0.5c-0.5,0.4-0.9,0.9-1.5,1.2C50.4,61.6,50.7,61.4,51,61.2z
          M51,55.9c0.3,0,0.8-0.5,1,0c-0.7-0.4-0.9,0.6-1.4,0.5C50.4,56.2,50.7,56,51,55.9z M44.9,60.9c0.6-1.1,1.4-2.2,2.6-3.1
          C46.7,59.1,45.5,59.8,44.9,60.9z M40.1,76.8c0.2,0.7,0.5,1.4,0.7,2.1C40.3,78.4,40.3,77.5,40.1,76.8z M78.6,71.2
          c0,0.7-0.1,1.4-0.3,2c-0.1,0.3-0.1,0.6-0.2,0.9c-0.1,0.3-0.1,0.6-0.2,0.9c-0.3,1.5-1,2.8-1.9,4.1c-0.1,0.2-0.3,0.4-0.5,0.6
          c-0.2,0.2-0.4,0.3-0.6,0.5c0.2-0.2,0.3-0.4,0.5-0.6c0.1-0.2,0.3-0.5,0.4-0.7c-0.8,1-1.7,2-2.7,2.8c0.2,0.2,0.1,0.5-0.1,0.5
          c-0.5-0.2-0.7,0.1-0.9,0.3c-0.4,0.3-0.8,0.5-1.2,0.8c-0.4,0.2-0.8,0.5-1.3,0.7c-1.2,0.6-2.4,0.9-3.7,1.1c-1.7,0.3-3.4,0.2-4.7-0.2
          c-0.4-0.1-0.6-0.2-0.8-0.5c-0.1-0.2-0.3-0.4-0.5-0.4c-0.3-0.1-0.5-0.3-0.7-0.5c-0.5-0.7-1.3-1.5-2-2.8c-0.6-1.1-1.3-2.5-1.9-3.8
          c-0.6-1.3-1.1-2.5-1.5-3.6c-0.4-1.2-0.6-2.2-0.7-3.2c0-0.5-0.1-0.9,0-1.3c0-0.2,0-0.4,0.1-0.5c0-0.2,0-0.3,0.1-0.5
          c0-0.2,0.1-0.4,0.2-0.6c0.3-0.8,0.8-1.5,1.6-1.8c0,0,0.1-0.1,0.1-0.1c0-0.3,0.2-0.6,0.5-0.5c0.2,0.1,0.4,0.1,0.5,0
          c0.7-0.1,1.4-0.5,1.9-0.2c0.4,0.2,0.9,0.3,1.3,0.6c0,0,0,0.1,0.1,0c0.2-0.2,0.2,0,0.1,0.1c0.1-0.1,0.1-0.2,0.2-0.4
          c-0.1,0.3,0,0.4,0.2,0.3c0.1,0,0.1,0,0,0.1c-0.1,0.2-0.1,0.1,0,0.2c0,0.1,0.2,0,0,0.2c-0.1,0.2-0.1,0.2,0,0.2
          c0.3-0.1,0.1,0.1,0.2,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0l0,0l0,0l0,0l0,0l0,0l0.2-0.1c0,0,0,0,0,0l-0.2,0.2C60,66.5,60,66.5,60,66.5
          c0.3,0.2,0.7,0.6,0.8,0.8c0-0.1-0.1-0.1-0.1-0.2c0,0,0,0,0,0c0.1,0.1,0.1,0.2,0.2,0.2c0,0,0-0.1,0,0c0,0,0.1,0,0.2,0.1
          c-0.1,0-0.1,0-0.1,0.2c0.2,0.2,0.1-0.1,0.5-0.1c-0.1,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.1,0.1-0.1,0.1c0.2,0.3,0.5-0.1,0.9-0.6
          c-0.2-0.1-0.1,0.1-0.4-0.1c0.6,0,0.8-0.4,0.8-0.7c0-0.4-0.1-0.6,0.1-1c0.1,0.1,0.1,0.2,0.2,0.2c0.1,0,0.3-0.1,0.5-0.1
          c0,0.1,0,0.2,0,0.3c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.1,0.2-0.2,0.3c0,0.1,0,0.2,0,0.3c-0.4-0.2-0.1-0.2-0.3-0.3
          c0,0.2-0.2,0.2,0.1,0.5c0.2,0.1,0.1,0.3,0,0.4c-0.2,0.2-0.6,0.2-0.2,0.6c0,0,0,0-0.1,0.1c0.3,0,0.3-0.1,0.3-0.3
          c0-0.3,0.3-0.4,0.4-0.7c0.4,0.1,0.3,0.2,0.3,0.3c0,0.1-0.2,0.2,0,0.4c0.2,0.3,0,0.4-0.2,0.4C63,68,63.2,68,63,67.9
          c0.1,0.3-0.5,0.2,0,0.8c0.1-0.1,0.1-0.2,0.2-0.1c-0.2,0.3-0.5,0.5-0.7,0.7c-0.3,0.1-0.4,0.3-0.6,0.5c0.1,0,0.2,0,0.2,0
          c0.8-0.2,1.6-1.1,2.1-2.3c0.1-0.2,0.1-0.3,0.1-0.5c0-0.2,0.1-0.3,0.2-0.5c0.1-0.1,0.2-0.2,0.3-0.4c0.1-0.1,0.1-0.3,0-0.4
          c-0.1,0.1,0.1,0.2-0.2,0.2c0-0.3-0.1-0.5,0.4-0.8c0.2,0.1,0,0.3,0.3,0.4c-0.1-0.2,0.1-0.5-0.3-0.5c-0.2,0-0.2-0.1-0.3-0.2
          c-0.1-0.1-0.1-0.2-0.4-0.1c0.3-0.3,0.1-0.6,0.1-0.8c0.3-0.1,0.1,0.3,0.5,0.1c-0.6-2.1-2.7-4-5.2-4.7c-2.4-0.7-5.2-0.2-7.6,1.2
          c0.6-0.5,1.3-1,2.1-1.1c1.1-0.2,2.1-0.6,3.2-0.5c0.8,0,1.6,0.1,2.3,0.3c0.3,0.1,0.4,0,0.4-0.4c-0.5-0.2-1-0.3-1.4-0.8
          c0.4-0.1,0.8,0.1,1.3,0.1c-0.1-0.6-0.7-0.3-1-0.6c0.4-0.3,0.4-0.6,0.1-0.9c0.2-0.2,0.3,0.1,0.5-0.1c-2.5-0.5-5.1-0.2-7.4,0.8
          c1.2-0.7,2.5-1.2,3.8-1.2c0.1-0.2-0.1-0.2-0.1-0.3c0.3-0.3,0.5-0.2,0.6,0.2c0.7-0.2,1.3,0,2-0.1c-0.1-0.3-0.4,0-0.5-0.3
          c0.3,0,0.6,0,0.9,0.1c0.3,0,0.6,0.1,0.9,0.1c-1.3-0.5-2.8-0.5-4.2-0.3c-1.4,0.2-2.7,0.7-4,1.2c2.3-1.2,4.9-1.8,7.5-1.5
          c2.6,0.3,5,1.4,6.9,3.1l0.1-0.1c-1.7-1.6-3.9-2.7-6.2-3.2c-2.4-0.4-4.8-0.2-7.1,0.6c0-0.2,0.1-0.4-0.1-0.6
          c-1.6,0.4-2.9,1.5-4.4,2.2c0.9-0.6,1.7-1.2,2.6-1.7c0.4-0.2,0.8-0.3,1.2-0.5c0.4-0.1,0.8-0.3,1.1-0.5c0.4-0.3,1,0,1.2-0.5
          c0.6,0.3,1.3-0.1,1.9-0.2c1.2-0.1,2.5-0.1,3.7,0.1c2.1,0.4,4,1.1,5.7,2.2c0.5,0.3,0.9,0.8,1.7,1.1c-1.1-1.1-2.4-2-3.7-2.6
          c-1.4-0.7-2.8-1.1-4.4-1.4c0.5-0.2,1.1,0,1.6-0.1c-1.1-0.3-2.2-0.4-3.3-0.4c0.1,0.3,0.4,0.2,0.4,0.4c-0.5,0.1-1.1,0-1.6,0
          c0-0.1,0.3-0.3,0.1-0.3c-0.5-0.1-1-0.1-1.4,0c-0.3,0.1-0.2,0.3-0.1,0.5c-0.5,0.1-1,0.4-1.6,0.4c0.3-0.3,0.7-0.3,1.1-0.5
          c-0.7-0.2-1.4-0.3-1.9,0.4c-0.1,0.1-0.2,0.1-0.3,0.1c-0.5,0.1-0.9,0.2-1.3,0.4c-0.4,0.2-0.9,0.4-1.3,0.6c-1,0.6-2,1.2-2.9,2
          c-1.7,1.4-3.1,3.3-4,5.3c-0.1,0.2-0.1,0.4-0.4,0.3c1.1-2.8,3.1-5.3,5.5-7.1c-2.7,1.8-4.9,4.5-6.1,7.5c-0.2,0.5-0.3,1-0.5,1.5
          c-0.1,0.5-0.3,1-0.4,1.5c-0.1,0.5-0.2,1-0.3,1.5c-0.1,0.5-0.1,1-0.1,1.5c0-0.5-0.1-1,0-1.5c0-0.5,0.1-1.1,0.2-1.6
          c0.1-0.5,0.2-1,0.4-1.5c0.2-0.5,0.3-1,0.5-1.5c0.5-1.3,1.2-2.6,1.9-3.9c-1,1.1-1.7,2.4-2.2,3.8c-0.3,0.8-0.5,1.6-0.8,2.4
          c-0.2,0-0.3-0.2-0.5-0.1c-0.5,1.4-0.7,2.9-0.7,4.3c0,1.4,0.1,2.7,0.2,4c-0.4-2.3-0.5-4.7-0.1-7.4c-0.3,0.6-0.3,1.4-0.8,1.8
          c0,0,0,0.2-0.1,0.3c-0.1,0.5-0.1,1-0.2,1.4c-0.1-1-0.1-2,0.1-3c0.1-1.1,0.4-2.1,0.7-3.2c-0.4,0.3-0.4,0.9-0.6,1.2
          c0-0.8,0.3-1.5,0.6-2.3c-0.2,0.2-0.3,0.6-0.5,0.7c-0.1-0.4,0.1-0.7,0.3-1c0.2-0.3,0.3-0.6,0.3-0.9c-0.2,0.1-0.3,0.3-0.4,0.6
          c0,0.1-0.1,0.2-0.1,0.2c-0.1,0.3-0.2,0.6-0.4,0.6c-0.4,0-0.5,0.5-0.6,0.9c-0.2,0.6-0.3,1.3-0.5,1.9c-0.1,0.6-0.2,1.3-0.3,2
          c-0.1,0.7-0.1,1.3-0.1,1.9c0,0.6,0,1.2,0,1.8c0,0.2,0,0.4,0,0.6c-0.2-0.7-0.2-1.5-0.2-2.2c0-0.8,0-1.6-0.1-2.5
          c-0.3,0.8-0.2,1.4-0.3,2C36,69.4,36,70.1,36,70.9c0,0.7,0,1.4,0.1,2.1c0.3,1.9,0.5,3.6,1.1,5.2c0.1,0.3,0.1,0.5,0,0.7
          c-0.1-0.1-0.1-0.2-0.2-0.3c-0.2-0.5-0.7-0.9-1-1.5c-0.1-0.3-0.3-0.6-0.4-0.9c-0.1-0.3-0.2-0.6-0.4-0.9c-0.5-1.2-0.7-2.5-0.9-3.8
          c0-0.2-0.1-0.4-0.1-0.6c0-0.2,0-0.4,0-0.6c0-0.4-0.1-0.8,0-1.2c0-0.6,0-1.3,0.2-2c0.3-1.2,0.5-2.4,0.8-3.5c0.2-0.5,0.3-1.1,0.5-1.5
          c0.3-0.6,0.3-1.3,0.6-1.9c0.1-0.2,0.2-0.4,0.4-0.7c0.1-0.2,0.3-0.4,0.4-0.6c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.2,0.3-0.4,0.4-0.6
          l0.4-0.6l0.5-0.5c0.8-1,1.7-1.8,2.7-2.7c1-0.8,2-1.6,3.1-2.3c0.2-0.1,0.4-0.2,0.6-0.4c0.2-0.1,0.4-0.3,0.4-0.7
          c0-0.2,0.3-0.2,0.5-0.4c-0.1,0.3-0.4,0.2-0.4,0.5c0.3-0.1,0.6-0.2,0.9-0.3c0.3-0.1,0.5-0.2,0.7-0.6c-0.2-0.1-0.3,0-0.5,0
          c0.5-0.4,1.1-0.7,1.8-0.8c-0.4,0.2-0.8,0.2-1.1,0.7c0.8,0,2.6-0.7,2.8-1.1c-0.4,0-0.7,0.4-1.2,0.2c2.2-1.1,4.6-1.4,6.9-1.9l0-0.2
          c-0.3,0-0.6,0-0.9,0.1l0-0.2c0.5,0.2,1.1-0.4,1.6,0.2c0.1,0.1,0.4,0.1,0.5-0.2c0.1-0.3,0.3-0.2,0.4-0.2c0.2,0.1,0.4,0.1,0.6,0
          c0.8-0.2,1.6,0,2.4,0c0.4,0,0.9,0.2,1.3,0.2c0.4,0,0.8,0.1,1.2,0.2c0.1,0,0.1,0,0.2-0.1c0.3-0.2,0.6-0.2,0.8,0
          c0.1,0.2,0.2,0.2,0.4,0.2c0.2-0.1,0.4,0,0.5,0c0.6,0.2,1.3,0.5,1.9,0.7c0.4,0.2,0.8,0.4,1.3,0.5c0.4,0,0.6,0.3,0.9,0.5
          c0.1,0,0.1,0.1,0,0.3c-0.3,0-0.3-0.3-0.8-0.2c0.7,0.3,1.3,0.8,1.6,1.5c0.1-0.1,0.1-0.3,0.2-0.3c0.2,0,0.4,0.2,0.3,0.3
          c-0.2,0.3,0,0.3,0.1,0.3c0.7,0.6,1.4,1.3,2,2c1.2,1.4,2.2,3,3,4.8c0.9,1.9,1.8,4,2.5,6.1c0,0.1,0.1,0.2,0,0.3
          c-0.3,0.3-0.1,0.6-0.1,0.9c0.2,0.8,0.3,1.5,0.5,2.2c0.1,0.4,0.1,0.8-0.1,1.2c-0.2,0.4,0.1,0.9,0.3,1.3C78.8,70,78.6,70.6,78.6,71.2
          z M56.2,59.4c0.7-0.2,1.4,0,1.8,0C57.6,59.4,56.9,59.5,56.2,59.4z M60.5,66.9C60.5,66.9,60.5,66.9,60.5,66.9
          C60.5,66.9,60.5,67,60.5,66.9c0,0,0,0,0.1,0.1C60.6,67,60.6,67,60.5,66.9c0.1,0.1,0.1,0.1,0.2,0.2C60.6,67.1,60.6,67,60.5,66.9
          C60.5,67,60.5,67,60.5,66.9C60.5,66.9,60.5,66.9,60.5,66.9C60.5,66.9,60.5,66.9,60.5,66.9z M60.6,66L60.6,66
          C60.6,66,60.6,66,60.6,66L60.6,66C60.6,66,60.6,66,60.6,66L60.6,66C60.6,66,60.6,66,60.6,66C60.6,66,60.6,66,60.6,66L60.6,66
          L60.6,66L60.6,66L60.6,66L60.6,66C60.6,66,60.6,66,60.6,66z M62.5,64.4c0.2,0.3,0.4,0.5,0.6,0.8c0.1,0.1,0.1,0.2-0.3,0.4
          C62.7,65.3,62.7,64.8,62.5,64.4z M63.9,62.5c0.3,0.3,0.4,0.6,0.7,0.8c0.1,0,0,0.1-0.1,0.2c-0.1,0.1-0.3,0.1-0.3,0.1
          c0-0.2-0.5,0-0.1-0.4C64.3,62.9,63.9,62.8,63.9,62.5z M57.4,57.3c-0.8,0-1.5,0.2-2.3,0.2c0.6-0.2,1-0.8,1.8-0.8
          C57.4,56.8,57.5,56.9,57.4,57.3z M58.3,58c-0.4-0.1-0.7,0.7-1.1,0c-0.3,0.5-0.6-0.1-1,0C56.8,57.7,57.5,57.6,58.3,58z"
        />
        <path
          className={classes.st3}
          d="M60.6,67.1c-0.1-0.1,0,0-0.1-0.1c0,0,0,0,0,0C60.5,67,60.5,67,60.6,67.1C60.6,67,60.6,67,60.6,67.1z"
        />
        <polygon className={classes.st3} points="60.6,66 60.6,66 60.6,66 	" />
        <path
          className={classes.st3}
          d="M62,58.6c-0.3-0.2-0.6-0.4-1-0.5c-0.2,0-0.4-0.1-0.6,0.2c1.4,0.4,2.4,1.2,3.6,1.8C63.6,59.4,62.8,59,62,58.6z"
        />
        <path
          className={classes.st3}
          d="M60.3,58.8c0.1,0.4,1.5,1.2,2.5,1.6C62.3,59.5,61.4,58.9,60.3,58.8z"
        />
        <path
          className={classes.st3}
          d="M39.3,59.6l0.5-1.2l-0.1,0c-0.3,0.5-0.6,1-0.8,1.5c-0.2,0.5-0.5,1.1-0.7,1.6l-0.1,0.2c0-0.1,0.1-0.2,0.1-0.2
          c0.3-0.6,0.6-1.3,1.2-1.5c0.1,0,0.3-0.4,0.4-0.6c0.4-0.9,1-1.7,1.5-2.5c0.1-0.2,0.3-0.4,0.1-0.5C40.6,57.4,39.9,58.5,39.3,59.6z"
        />
        <path
          className={classes.st3}
          d="M43.4,58.4l0.2,0.1c1.4-1.9,3.2-3.5,5.2-4.7C46.6,54.7,44.9,56.5,43.4,58.4z"
        />
        <path
          className={classes.st3}
          d="M61.3,57.4c0.7,0.1,1.3,0.7,2.1,0.8C62.8,57.6,62.2,56.9,61.3,57.4z"
        />
        <path
          className={classes.st3}
          d="M64.5,58.1c-1.2-0.8-2.6-1.8-4.2-1.9C61.8,56.7,63.2,57.3,64.5,58.1z"
        />
        <path
          className={classes.st3}
          d="M77.5,64.5c0.1,1.1,0.6,2,0.6,3C78.1,66.6,78,65.6,77.5,64.5z"
        />
        <path
          className={classes.st3}
          d="M39.5,62.7c0.2-0.3,0.4,0,0.7-0.4l0,0c-0.4-0.7,0.5-1.5,0.6-2.4c-0.5,0.8-0.8,1.5-1,2.3
          C39.6,62.3,39.6,62.4,39.5,62.7z"
        />
        <path
          className={classes.st3}
          d="M78.1,73.6L78.1,73.6c0.2-0.6,0.3-1.3,0.3-2l-0.1,0C78.2,72.3,78.2,72.9,78.1,73.6z"
        />
        <path
          className={classes.st3}
          d="M64.6,54.7c-0.9-0.5-2-0.9-3-1.2C62.6,54,63.6,54.4,64.6,54.7z"
        />
        <path
          className={classes.st3}
          d="M61.9,68.4c0.1,0,0.2-0.2,0.5-0.1c0.1,0,0-0.1,0-0.2C62,68,61.8,68.1,61.9,68.4z"
        />
        <polygon
          className={classes.st3}
          points="62.3,68.1 62.3,68.1 62.3,68.1 	"
        />
        <path
          className={classes.st3}
          d="M64.4,67.5c0.3-0.3,0.2-0.7,0.3-1C64.4,66.8,64.4,67.1,64.4,67.5z"
        />
        <path
          className={classes.st3}
          d="M41,61.6c-0.1,0.3-0.2,0.6-0.3,0.8c-0.3,0.8-0.8,1.6-0.9,2.5c0.2-0.9,0.8-1.6,1-2.4C40.9,62.2,41,61.9,41,61.6
          z"
        />
        <path
          className={classes.st3}
          d="M39.8,64.9C39.8,64.9,39.8,64.9,39.8,64.9L39.8,64.9C39.7,64.9,39.8,64.9,39.8,64.9
          C39.8,64.9,39.8,64.9,39.8,64.9C39.8,64.9,39.8,64.9,39.8,64.9z"
        />
        <path
          className={classes.st3}
          d="M77.6,75.1c0.2-0.1,0.3-0.2,0.3-0.4c0-0.2,0-0.3,0-0.5C77.9,74.5,77.8,74.8,77.6,75.1z"
        />
        <path
          className={classes.st3}
          d="M64.6,59.3c0.3,0.2,0.6,0.4,0.8,0.6C65.1,59.7,65.3,58.9,64.6,59.3z"
        />
      </g>
    </svg>
  );
};

export default LogoFull;
