import {
  InfoCardVariants,
  StructuredMetadataTable,
} from '@backstage/core-components';
import { useEntity } from '@backstage/plugin-catalog-react';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
} from '@material-ui/core';
import React from 'react';
import { AwsAccountEntity } from '@internal/plugin-aws-resources-common';

const useStyles = makeStyles({
  gridItemCard: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 10px)', // for pages without content header
    marginBottom: '10px',
  },
  fullHeightCard: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  gridItemCardContent: {
    flex: 1,
  },
  fullHeightCardContent: {
    flex: 1,
  },
});

export interface AboutCardProps {
  variant?: InfoCardVariants;
}

export function AwsAccountInfinityCard(props: AboutCardProps) {
  const { variant } = props;
  const classes = useStyles();
  const entity = useEntity().entity as AwsAccountEntity;
  let cardClass = '';
  if (variant === 'gridItem') {
    cardClass = classes.gridItemCard;
  } else if (variant === 'fullHeight') {
    cardClass = classes.fullHeightCard;
  }

  let cardContentClass = '';
  if (variant === 'gridItem') {
    cardContentClass = classes.gridItemCardContent;
  } else if (variant === 'fullHeight') {
    cardContentClass = classes.fullHeightCardContent;
  }

  const infinitySpec = entity.spec.infinity;
  if (infinitySpec === undefined) {
    return <></>;
  }
  const infinityMetadata: Record<string, any> = {};
  if (infinitySpec.infinityCluster !== undefined) {
    infinityMetadata.Cluster = infinitySpec.infinityCluster;
  }
  if (infinitySpec.infinityNetworking !== undefined) {
    infinityMetadata.Networking = infinitySpec.infinityNetworking;
  }
  if (
    infinitySpec.infinityAllowedVerticals !== undefined &&
    infinitySpec.infinityAllowedVerticals.length > 0
  ) {
    infinityMetadata['Allowed Verticals'] =
      infinitySpec.infinityAllowedVerticals;
  }
  if (infinitySpec.skipInfinityClusterAssignment) {
    infinityMetadata['Skip Cluster Assignment'] =
      infinitySpec.skipInfinityClusterAssignment;
  }
  if (infinitySpec.vpc !== undefined) {
    infinityMetadata.VPC = infinitySpec.vpc;
  }
  if (infinitySpec.additionalVpcs !== undefined) {
    const values = [];
    if (infinitySpec.additionalVpcs.eu_central_1) {
      values.push(`eu-central-1: ${infinitySpec.additionalVpcs.eu_central_1}`);
    }
    if (infinitySpec.additionalVpcs.eu_west_1) {
      values.push(`eu-west-1: ${infinitySpec.additionalVpcs.eu_west_1}`);
    }
    infinityMetadata['Additional VPC'] = values;
  }
  if (Object.entries(infinityMetadata).length === 0) {
    return <></>;
  }

  return (
    <Card className={cardClass}>
      <CardHeader title="Infinity" />
      <Divider />
      <CardContent className={cardContentClass}>
        <StructuredMetadataTable metadata={infinityMetadata} />
      </CardContent>
    </Card>
  );
}
