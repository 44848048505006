import { EntityFilter } from '@backstage/plugin-catalog-react';
import { Entity } from '@backstage/catalog-model';
import { isAwsAccountEntity } from '@internal/plugin-aws-resources-common';

export class AuthorityEntityFilter implements EntityFilter {
  constructor(readonly entityRef?: string) {}
  filterEntity(entity: Entity): boolean {
    if (!isAwsAccountEntity(entity)) {
      return false;
    }
    const authorityRelations = entity.relations?.filter(relation =>
      relation.type.endsWith('AuthorityOf'),
    );

    return (
      authorityRelations !== undefined &&
      authorityRelations.find(
        relation => relation.targetRef === this.entityRef,
      ) !== undefined
    );
  }
}
